.ps-menu-button .ps-active {
  color: #044d6e;
}
.ps-menu-button {
  svg {
    fill: #333333;
    path {
      fill: #333333;
    }
  }

  &.ps-active {
    background-color: transparent;

    font-weight: 600;
    svg {
      fill: #044d6e;
      path {
        fill: #044d6e;
      }
    }
  }
  &:hover {
    background-color: transparent !important;
    svg {
      fill: #044d6e;
      path {
        fill: #044d6e;
      }
    }
  }
}

.main-menu-items > .ps-menu-button {
  font-size: 0.75rem !important;
  padding: 8px 0px !important;
  text-transform: uppercase;
  font-weight: 500;
}
.submenu-items > .ps-menu-button {
  padding: 8px 16px !important;
}
.ps-submenu-content {
  background: transparent !important;
   .child-submenu-items > .ps-menu-button{
      padding: 8px 16px !important;
        &.ps-active {
          background-color: #e6f7fe;
        }
        &:hover {
          background-color: #e6f7fe !important;
        }
    }
    .child-menu-items > .ps-menu-button {
        padding: 8px 36px !important;
        &.ps-active {
          background-color: #e6f7fe !important;
        }
        &:hover {
           background-color: #e6f7fe !important;
        }
      }
  .submenu-items {
    .ps-menu-button {
      &.ps-active {
        background-color: #e6f7fe;
      }
    }
    &:hover {
      .ps-menu-button {
        background-color: #e6f7fe !important;
      }
    }
  }
}
.ps-submenu-expand-icon {
  span {
    border-right: 2px solid #737373;
    border-bottom: 2px solid #737373;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.sidebarOpen {
  .main-menu-items {
    > .ps-menu-button {
      padding: 0px 0px !important;
      flex-direction: column;
      height: 50px !important;
      margin: 5px 12px !important;
      .ps-menu-label {
        font-size: 10px;
        line-height: 13px;
        max-width: 50px;
      }
      .ps-menu-icon {
        margin-right: 0;
      }
      &:hover {
        background-color: #e6f7fe !important;
      }
      &.ps-active {
        background-color: #e6f7fe !important;
      }
    }
  }
  .submenu-items {
    > .ps-menu-button {
      padding: 8px 8px !important;
      margin: 0 8px !important;
    }
  }
  .ps-submenu-expand-icon {
    right: 15px;
    top: 35%;
    span {
      border-right: 2px solid #a6a6a6;
      border-bottom: 2px solid #a6a6a6;
      width: 7px;
      height: 7px;
      transform: rotate(-45deg);
      border-radius: 0;
      background-color: transparent;
    }
  }
  .ps-submenu-content {
    background: #ffffff !important;
    padding: 10px 0;
    max-height: 450px;
    overflow-y: auto !important;
    top: 20px !important;
  }
}
