.breadcrum-box{
    background-color: #FAFBFC;
    width: fit-content;
    padding: 8px;
    border-radius: 4px;
    ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        li{
            line-height: 1;
            padding: 0 3px;
            a{
                font-size: 12px;
                line-height: 16px;
                color: #222222;
                display: inline-flex;
                i{
                    padding-left: 6px;
                }
                &.active{
                    font-weight: 600;
                    color: #044D6E;
                }
            }
            &:first-child{
                a{
                    cursor: default;
                }
            }
        }
    }
}