.login-user-profile-header-wrapper{
    margin-bottom: 24px;
    h2{
        margin: 0;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 40px;
    }
    .login-user-header-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .login-user-box {
            display: flex;
            align-items: center;
            gap: 16px;
            .login-user-img{
                width: 95px;
                height: 95px;
                border: 1px solid #F2F2F2;
                padding: 5px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                }
            }
            .login-user-title{
                h4{
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 38px;
                    color: #333333;
                    margin: 0;
                }
                span{
                    background-color: #E6F7FE;
                    padding: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 19px;
                    color: #737373;
                    border-radius: 6px;
                }
            }
        }
        .login-user-action-box{
            display: flex;
            align-items: center;
            gap: 24px;
            .login-user-action-btn{
                font-size: 14px;
                display: inline-flex;
                align-items: center;
                gap: 5px;
                color: #333333;
                position: relative;
                cursor: pointer;
                a{
                    color: #333333;
                }
                &::after{
                    content: "";
                    width: 1px;
                    height: 21px;
                    background-color: #d9d9d9;
                    position: absolute;
                    right: -12px;
                    top: 0px;
                }
                &:last-child{
                    &:after{
                        content: none;
                    }
                }
            }
        }
    }
}
.login-user-profile-info{
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    padding: 16px;
    min-height: 350px;
    .profile-detail-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        .profile-info-box {
            h5{
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0;
            }
            label {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: #8C8C8C;
                margin-bottom: 8px;
            }
            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;
                color: #333333;
                margin: 0;
                border: 1px solid #D9D9D9;
                padding: 8px 16px;
                border-radius: 4px;
                min-height: 36px;
            }
            &.full-width {
                grid-column: span 4;
            }
        }
    }
}
#change-password-modal{
    .password-field-box {
        position: relative;
        .form-control {
            padding-right: 35px;
        }
        i {
            position: absolute;
            right: 15px;
            bottom: 8px;
            cursor: pointer;
        }
        .invalid-feedback {
            position: absolute;
            font-size: 11px;
        }
    }
}