.usage-trans-content-wrapper{
    .api-log-header{
        margin-bottom: 24px;
        h2 {
          font-size: 28px;
          line-height: 38px;
          font-weight: 600;
          color: #044D6E;
        }
    }
    .report-filter-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .report-filter-item-wrapper {
          display: flex;
          align-items: center;
          gap: 12px;
          .filter-item-box {
            position: relative;
            .calender-box{
                .calendar-date-input{                
                    background-image: url(../../assets/images/calender-icon.svg);
                    background-size: 16px;
                    background-position: 95% 12px;
                    background-repeat: no-repeat;
                }
            }
            label {
              font-size: 12px;
              line-height: 16px;
              font-weight: 500;
              color: #737373;
            }
            input {
              &.form-control {
                min-width: 120px;
                max-width: 120px;
              }
            }
            select {
              &.form-select {
                min-width: 120px;
                max-width: 120px;
              }
            }
            &.filter-checkbox-items {
              padding-top: 35px;
            }
            &.clear-all-box {
              padding-top: 25px;
              position: relative;
              .clear-filter-btn {
                border: none;
                background: none;
                text-decoration: underline;
                font-size: 14px;
                line-height: 19px;
                color: #333333;
              }
              &:after {
                content: "";
                width: 1px;
                background: #d9d9d9;
                height: 21px;
                position: absolute;
                left: -10px;
                top: 30px;
              }
            }
            .react-calendar {
              right: initial;
            }
            &.type-filter-item {
              .dropdown-content {
                min-width: 285px;
                width: 100%;
              }
            }
          }
        }
        .report-export-box {
          padding-top: 35px;
          .report-export-btn {
            border: none;
            background: none;
            font-size: 14px;
            line-height: 19px;
            color: #333333;
            display: flex;
            gap: 8px;
            align-items: center;
            cursor: pointer;
          }
        }
    }
    // .filter-item-detail-wrapper {
    //     margin-top: 24px;
    //     .filter-item-detail-title {
    //       margin-bottom: 16px;
    //       h4 {
    //         font-size: 16px;
    //         font-weight: 600;
    //         line-height: 21px;
    //         color: #333333;
    //         margin: 0;
    //       }
    //     }
    //     .filter-item-detail-table-wrapper {
    //       max-width: 100%;
    //       overflow: auto;
    //       min-height: 350px;
    //       border: 1px solid #f2f2f2;
    //       border-radius: 8px;
    //     }
    // }
}

.filter-item-detail-wrapper {
    margin-top: 24px;
    .filter-item-detail-title {
      margin-bottom: 16px;
      h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        color: #333333;
        margin: 0;
      }
    }
    .filter-item-detail-table-wrapper {
      max-width: 100%;
      overflow: auto;
      min-height: 250px;
      border: 1px solid #f2f2f2;
      border-radius: 8px;
    }
}
  
  .sidebarOpen {
    .report-filter-wrapper {
      .report-filter-item-wrapper {
        .filter-item-box {
          position: relative;
          input {
            &.form-control {
              min-width: 130px;
              max-width: 130px;
            }
          }
          select {
            &.form-select {
              min-width: 130px;
              max-width: 130px;
            }
          }
        }
      }
    }
    .rmsc.multi-select {
      max-width: 130px;
      min-width: 130px;
    }
  }
  .disabled{
    background-color: #bfbfbf !important;
    border-color: #bfbfbf !important;
  }