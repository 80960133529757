.api-log-content-wrapper{
    .api-log-header{
        margin-bottom: 24px;
        h2 {
          font-size: 28px;
          line-height: 38px;
          font-weight: 600;
          color: #044D6E;
        }
    }
    .report-filter-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .report-filter-item-wrapper {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 4px;
          .filter-item-box {
            position: relative;
            min-width: 130px;
            max-width: 130px;
            .invalid-feedback {
              position: absolute;
              font-size: 11px;
            }
            label {
              font-size: 12px;
              line-height: 16px;
              font-weight: 500;
              color: #737373;
            }
            input {
              &.form-control {
                min-width: 130px;
                max-width: 130px;
                padding: 10px 10px;
              }
            }
            select {
              &.form-select {
                min-width: 120px;
                max-width: 120px;
              }
            }
            &.filter-checkbox-items {
              padding-top: 35px;
            }
            &.clear-all-box {
              padding-top: 25px;
              margin-left: 16px;
              position: relative;
              .clear-filter-btn {
                border: none;
                background: none;
                text-decoration: underline;
                font-size: 14px;
                line-height: 19px;
                color: #333333;
              }
              &:after {
                content: "";
                width: 1px;
                background: #d9d9d9;
                height: 21px;
                position: absolute;
                left: -10px;
                top: 28px;
              }
            }
            .react-calendar {
              right: initial;
            }
            &.type-filter-item {
              .dropdown-content {
                min-width: 285px;
                width: 100%;
              }
            }
          }
        }
        .report-export-box {
          padding-top: 35px;
          .report-export-btn {
            border: none;
            background: none;
            font-size: 14px;
            line-height: 19px;
            color: #333333;
            display: flex;
            gap: 8px;
            align-items: center;
            cursor: pointer;
          }
        }
    }
}

.filter-item-detail-wrapper {
    margin-top: 24px;
    .filter-item-detail-title {
      margin-bottom: 16px;
      h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        color: #333333;
        margin: 0;
      }
    }
    .filter-item-detail-table-wrapper {
      max-width: 100%;
      overflow-x: auto;
      border: 1px solid #f2f2f2;
      border-radius: 8px;
    }
}

#param_detail_modal{
  max-width: 850px;
  .param-content-box {
    width: 100%;
    text-align: left;
    &:first-child{
      padding-bottom: 20px;
    }
    &:last-child{
      padding-top: 20px;
      border-top: 1px solid #d9d9d9;
    }
    h4{
      font-size: 14px;
    }
    textarea{
      min-height: 150px;
    }
  }
}
  
  .sidebarOpen {
    .report-filter-wrapper {
      .report-filter-item-wrapper {
        .filter-item-box {
          position: relative;
          input {
            &.form-control {
              min-width: 130px;
              max-width: 130px;
            }
          }
          select {
            &.form-select {
              min-width: 130px;
              max-width: 130px;
            }
          }
        }
      }
    }
    .rmsc.multi-select {
      max-width: 130px;
      min-width: 130px;
    }
  }
  