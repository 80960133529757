.patients-listing-wrapper {
  .listing-table-wrapper {
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    margin-top: 16px;
    max-width: 100%;
    overflow: auto;
  }
  tbody{
    tr{
      td{
        &:nth-child(3){
          text-align: center;
        }
        &:nth-child(5){
          text-align: center;
        }
        &:last-child{
          width: 50px;
          text-align: right;
        }
      }
      &:nth-child(1){
        td{
          &:last-child{
            .dropdown-menu.show{
              top: -90px !important;
              transform: translate3d(-45.3333px, 69.3333px, 0px) !important;
            }
          }
        }
      }
      &:nth-child(2){
        td{
          &:last-child{
            .dropdown-menu.show{
              top: -110px !important;
              transform: translate3d(-45.3333px, 69.3333px, 0px) !important;
            }
          }
        }
      }
      &:nth-child(3){
        td{
          &:last-child{
            .dropdown-menu.show{
              top: -150px !important;
              transform: translate3d(-175.3333px, 69.3333px, 0px) !important;
            }
          }
        }
      }
    }
  }
  thead{
    tr{
      th{
        &:nth-child(3){
          text-align: center;
        }
        &:nth-child(5){
          text-align: center;
        }
      }
    }
  }
  td,
  th {
    white-space: nowrap;
    border: none;
  }
  th {
    background-color: #fafbfc;
    font-size: 12px;
    font-weight: 600;
    color: #8c8c8c;
    line-height: 16px;
    padding: 14px 12px;
    text-transform: uppercase;
  }
  td {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
    line-height: 19px;
    padding: 4px 12px;
    vertical-align: middle;
  }
  .form-check-input[type="checkbox"] {
    width: 18px;
    height: 18px;
  }
  span.status-circle {
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-right: 4px;
    &.active{
        background: #388f4b;
    }
    &.inactive{
        background: #D34343;
    }
  }
  .dropdown-menu {
    .dropdown-item {
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      padding: 10px 12px;
      color: #333333;
      &:last-child {
        border: none;
      }
    }
    &.show {
      border: 1px solid #f2f2f2;
      box-shadow: 0px 4px 4px 0px #0000000a;
    }
  }
}

@media(min-width: 1600px){
  .patients-listing-wrapper tbody tr:nth-child(3) td:last-child .dropdown-menu.show {
      top: -150px !important;
      transform: translate3d(-45.3333px, 69.3333px, 0px) !important;
  }
}
