.athena-trans-content-wrapper{
    .athena-trans-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      h2 {
        font-size: 28px;
        line-height: 38px;
        font-weight: 600;
        color: #044D6E;
        margin: 0;
      }p{
        margin: 0;
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
        color: #595959;
      }
      .athena-load-options {
          display: flex;
          align-items: center;
          gap: 20px;
          .athena-load-options-box{
            position: relative;
            cursor: pointer;
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            &:after{
              content: '';
              width: 1px;
              height: 15px;
              position: absolute;
              top: 5px;
              right: -8px;
              background-color: #D9D9D9;
            }
            &:last-child{
              &::after{
                content: none;
              }
            }
          }
      }
    }
    .report-filter-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .report-filter-item-wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 12px;
          .filter-item-box {
            position: relative;
            min-width: 125px;
            max-width: 125px;
            .calender-box{
                .calendar-date-input{                
                    background-image: url(../../assets/images/calender-icon.svg);
                    background-size: 16px;
                    background-position: 95% 12px;
                    background-repeat: no-repeat;
                }
            }
            label {
              font-size: 12px;
              line-height: 16px;
              font-weight: 500;
              color: #737373;
            }
            input {
              &.form-control {
                min-width: 120px;
                max-width: 120px;
              }
            }
            select {
              &.form-select {
                min-width: 120px;
                max-width: 120px;
              }
            }
            &.filter-checkbox-items {
              padding-top: 35px;
            }
            &.clear-all-box {
              padding-top: 25px;
              position: relative;
              max-width: 80px;
              min-width: 80px;
              .clear-filter-btn {
                border: none;
                background: none;
                text-decoration: underline;
                font-size: 14px;
                line-height: 19px;
                color: #333333;
              }
              &:after {
                content: "";
                width: 1px;
                background: #d9d9d9;
                height: 21px;
                position: absolute;
                left: -5px;
                top: 30px;
              }
            }
            .react-calendar {
              right: initial;
            }
            &.type-filter-item {
              .dropdown-content {
                min-width: auto;
                width: 100%;
              }
              &.type-filter-item {
                .dropdown-content {
                  min-width: auto;
                  width: 100%;
                }
                .react-select__menu{
                  min-width: auto;
                  width: 100%;
                }
              }
            }
            &.location-filter-item {
              .dropdown-content {
                min-width: 250px;
                width: 250px;
              }
              .react-select__menu{
                min-width: 250px;
                width: 250px;
              }
            }
          }
        }
        .report-export-box {
          padding-top: 35px;
          .report-export-btn {
            border: none;
            background: none;
            font-size: 14px;
            line-height: 19px;
            color: #333333;
            display: flex;
            gap: 8px;
            align-items: center;
            cursor: pointer;
          }
        }
    }
    .filter-item-detail-table-wrapper{
      table{
        th{
          &:nth-child(4){
            text-align: center;
          }
          &:nth-child(8){
            text-align: center;
          }
        }
        tbody{
          tr{
            td{
              &:nth-child(4){
                text-align: center;
              }
              &:nth-child(8){
                text-align: center;
              }
            }
          }
        }
      } 
    } 
}

.filter-item-detail-wrapper {
    margin-top: 24px;
    .filter-item-detail-title {
      margin-bottom: 16px;
      h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        color: #333333;
        margin: 0;
      }
    }
    .filter-item-detail-table-wrapper {
      max-width: 100%;
      overflow: auto;
      min-height: 250px;
      border: 1px solid #f2f2f2;
      border-radius: 8px;
    }
}
  
  .sidebarOpen {
    .report-filter-wrapper {
      .report-filter-item-wrapper {
        .filter-item-box {
          position: relative;
          min-width: 125px;
          input {
            &.form-control {
              min-width: 130px;
              max-width: 130px;
            }
          }
          select {
            &.form-select {
              min-width: 130px;
              max-width: 130px;
            }
          }
        }
      }
    }
    .rmsc.multi-select {
      max-width: 130px;
      min-width: 130px;
    }
  }
  