$primary-color: #0470A1;
$primary-950: #044d6e;
$primary-800: #0479AE;
$primary-700: #069CE0;
$primary-500: #0470a1;
$black-850: #333333;
$black-600: #737373;
$black-500: #8C8C8C;
$black-200: #D9D9D9;
$secondry-color: #0734A9;
$green-color: #5C8A10;
$success-color: #108A2B;
$warning-color: #FFA500;
$error-color: #D34343;