.product-master-profile-page-wrapper{
    .profile-detail-box-wrapper{
        border: 1px solid #F2F2F2;
        border-radius: 8px;
        padding: 16px;
        min-height: 350px;
        .profile-detail-box {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            .profile-info-box {
                height: auto;
                label {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    color: #8C8C8C;
                }
                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 19px;
                    color: #333333;
                    margin: 0;
                }
                &.full-width {
                    grid-column: span 3;
                }
            }
        }
    }
}