@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import "./variables";
@import "react-date-picker/dist/DatePicker.css";
@import 'react-toastify/dist/ReactToastify.min.css';
$theme-colors: (
  primary: #044d6e,
  primary-500: #0470a1,
  primary-700: #069CE0,
  primary-800:#0479AE,
  secondry: #0734a9,
  accent: #5c8a10,
  success: #108a2b,
  warning: #ffa500,
  error: #d34343,
);
//$table-hover-bg: #f4f9fd;
$table-hover-bg: #FAFBFC;
$form-check-input-checked-bg-color: #0470a1;
$form-check-input-border-radius: 0.15em !default;
$form-check-input-focus-box-shadow: none;
$dropdown-link-active-bg: #f8f9fa !default;
$tooltip-bg: $black-850 !default;
$tooltip-border-radius: 39px !default;
$tooltip-padding-y: 8px !default;
$tooltip-padding-x: 12px !default;
$btn-border-radius: 4px !default;
$accordion-border-color: #F2F2F2;
@import "bootstrap/scss/bootstrap.scss";
body {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  background-color: #fafafa !important;
}
ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #bfbfbf;
  font-size: 14px;
}
::placeholder {
  color: #bfbfbf;
  font-size: 14px;
}

/* scroll css */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: $black-200;
  border-radius: 16px;
}

.form-control,
.form-select {
  font-size: 0.875rem;
  color: $black-850;
  font-weight: 500;
  line-height: 19px;
  min-height: 40px;
  border-color: $black-200;
  border-radius: 4px;
  padding: 10px 10px;
}
.form-control:focus,
.form-control:active,
.form-select:focus,
.form-select:active {
  border-color: $black-500;
  box-shadow: none;
}
.form-check-input[type="radio"]:checked {
  background-image: none;
  border-width: 3px;
  background-color: #0470a1;
  border-color: #ffffff;
  position: relative;
  &:after {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #1565c0;
    position: absolute;
    left: -0.29rem;
    top: -0.28rem;
    border-radius: 50%;
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.page-title {
  margin-top: 8px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-title h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.table-hover > tbody > tr:hover > * {
  background-color: #f4f9fd;
}
.table > tbody >tr {
  &.active {
    td{
      background-color: #e6f2fb;
    }    
  }
}

.content-wrapper {
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  width: calc(100% - 250px);
  height: calc(100vh - 80px);
  overflow-x: auto;
}
.sidebarOpen .content-wrapper {
  width: calc(100% - 80px);
}

span.status-circle {
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-right: 4px;
  &.active {
    background: #388f4b;
  }
  &.inactive {
    background: #d34343;
  }
}

.npi-search-result-table-wrapper{
  .table{
      tr{
          &:hover{
            td{
              background-color: #e6f2fb!important;
            }
          }
      }
  }
}

span.status-tag {
  width: auto;
  min-width: 36px;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  &.active{
    background-color: #EBFBEE;
    color: #388F4B;
  }
  &.inactive{
    background-color: #FFF5F5;
    color: #D34343;
  }
  &.warning{
    background-color: #FFF9DB;
    color: #FFA500;
  }
}

.profile-info-box p {
  padding: 8px 16px;
  border: 1px solid $black-200;
  border-radius: 4px;
  margin-top: 8px !important;
}



.listing-table-wrapper{
  height: calc(100vh - 230px);
  .table{
    thead{
      th {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    } 
  }
  span.status-circle {
    display: inline-block;
    width: auto!important;
    height: auto!important;
    margin-right: 0!important;
    min-width: 36px;
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px!important;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    &.active {
      /* background: #388f4b; */
      background-color: #EBFBEE!important;
      color: #388F4B;
    }
    &.inactive {
      background-color: #FFF5F5!important;
      color: #D34343;
    }
}
}


/****** Report Filter CSS ***********/

.report-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .report-filter-item-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    .filter-item-box {
      position: relative;
      z-index: 2;
      font-size: 0.875rem;
      font-weight: 500;
      label {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: $black-600;
      }
      input {
        &.form-control {
          min-width: 120px;
          max-width: 120px;
        }
      }
      &.filter-checkbox-items {
        padding-top: 35px;
      }
      &.clear-all-box {
        padding-top: 35px;
        .clear-filter-btn {
          border: none;
          background: none;
          text-decoration: underline;
          font-size: 14px;
          line-height: 19px;
          color: $black-850;
        }
      }
      .react-calendar {
        right: initial;
      }
      &.type-filter-item {
        .dropdown-content {
          min-width: 285px;
          width: 100%;
        }
        .react-select__menu{
          min-width: 285px;
          width: 100%;
        }
      }
      &.location-filter-item {
        .dropdown-content {
          min-width: 400px;
          width: 100%;
        }
        .react-select__menu{
          min-width: 400px;
          width: 100%;
        }
      }
    }
  }
  .report-export-box {
    padding-top: 35px;
    display: flex;
    gap: 16px;
    .report-export-btn {
      border: none;
      background: none;
      font-size: 14px;
      line-height: 19px;
      color: $black-850;
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
    }
  }
}

.filter-item-detail-wrapper {
  margin-top: 24px;
  .filter-item-detail-title {
    margin-bottom: 16px;
    h4 {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      color: $black-850;
      margin: 0;
    }
  }
}

.filter-item-detail-table-wrapper{
  max-width: 100%;
  overflow: auto;
  min-height: 250px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  table {
    margin-bottom: 0;
    td,
    th {
      white-space: nowrap;
      border: none;
    }
    th {
      background-color: #F2F9FF!important;
      font-size: 12px;
      font-weight: 600;
      color: $black-850 !important;
      line-height: 16px;
      padding: 14px 12px;
      text-transform: uppercase;
    }
    td {
      font-size: 14px;
      font-weight: 400;
      color: $black-850 !important;
      line-height: 19px;
      padding: 14px 12px!important;
      vertical-align: middle;
      span.status-circle {
        display: inline-block;
        width: auto!important;
        height: auto!important;
        margin-right: 0!important;
        min-width: 36px;
        display: inline-block;
        padding: 4px 8px;
        border-radius: 4px!important;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        text-align: center;
        &.active {
          /* background: #388f4b; */
          background-color: #EBFBEE!important;
          color: #388F4B;
        }
        &.inactive {
          background-color: #FFF5F5!important;
          color: #D34343;
        }
      }
    }
  }
}


.listing-searchbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 400px;
  .listing-searchbar-item-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    .form-check-inline{
      margin-right: 0;
    }
    .listing-searchbar-item-box{
      &.clear-all-box {
        .clear-filter-btn {
          border: none;
          background: none;
          text-decoration: underline;
          font-size: 13px;
          line-height: 18px;
          color: #333333;
          padding: 0;
      }
      }
      input{
        min-width: 250px;
        padding: 8px 10px !important;
        min-height: 35px;
      }
    }
  }
}

.sidebarOpen {
  .report-filter-wrapper {
    .report-filter-item-wrapper {
      .filter-item-box {
        position: relative;
        input {
          &.form-control {
            min-width: 130px;
            max-width: 130px;
          }
        }
        select {
          &.form-select {
            min-width: 130px;
            max-width: 130px;
          }
        }
      }
    }
  }
  .rmsc.multi-select {
    max-width: 130px;
    min-width: 130px;
  }
}

/****** Report Filter CSS End ***********/

span.action-item-name {
  display: -webkit-inline-box;
  max-width: 300px;
  min-height: 22px;
  margin-bottom: -5px;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 25px;
}
.react-select__indicator-separator{
  display: none;
}
.barcodes-list-wrapper{
  >label {
    margin-top: 10px;
  }
  .barcodes-list-box {
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    padding-left: 5px;
    margin-top: 10px;
    label {
      margin-top: 0px;
    }
  }

}



.edit-btn-box{
  .btn {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    white-space: nowrap;
  }
} 

/****** Detail page CSS ***********/

.profile-detail-box-wrapper{
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  padding: 16px;
  min-height: 350px;
  .profile-detail-box {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      .profile-info-box {
          height: auto;
          label {
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              color: $black-500;
          }
          p {
              font-size: 14px;
              font-weight: 500;
              line-height: 19px;
              color: $black-850;
              margin: 0;
              overflow-wrap: anywhere;
              min-height: 36px;
          }
          &.profile-section-title:not(:first-child){
            margin-top: 10px;
          }
          &.full-width {
              grid-column: span 3;
          }
      }
  }
}
.add-athena-trans-box{
  .profile-info-box{
    &.profile-section-title:not(:first-child){
      margin-top: 10px;
    }
  }
}

/****** Detail page CSS End ***********/

/****** React Calendar CSS ***********/

.react-calendar.hide {
  display: none;
}
.react-calendar {
  position: absolute;
  right: 0;
  min-width: 395px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
  box-shadow: 0px 4px 8px 0px #00000014;
  z-index: 1;
}
.react-calendar__navigation button {
  font-size: 20px;
  color: $black-850;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: transparent;
}
button.react-calendar__navigation__prev2-button {
  display: none;
}
button.react-calendar__navigation__next2-button {
  display: none;
}
button.react-calendar__navigation__label {
  order: -1;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: $black-850;
}
.react-calendar__month-view__weekdays {
  abbr[title] {
    text-decoration: none;
    text-transform: capitalize;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: $black-850;
  }
}
.react-calendar__month-view__days__day {
  color: #858585;
}
.react-calendar__month-view__days__day:enabled:hover,
.react-calendar__month-view__days__day:enabled:focus {
  background-color: #f4f9fd;
  color: #0470a1;
  font-weight: 600;
}
.react-calendar__month-view__days__day.react-calendar__tile--active {
  background-color: #f4f9fd;
  color: #0470a1;
  font-weight: 600;
}

.react-calendar__tile--now {
  background-color: #f4f9fd;
}
.react-calendar__month-view__days__day {
  color: #858585;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.calender-box {
  position: relative;
  z-index: 2;
  .calendar-date-input{                
      background-image: url(../assets/images/calender-icon.svg);
      background-size: 16px;
      background-position: 95% 12px;
      background-repeat: no-repeat;
  }
}

/****** React Calendar CSS End ***********/

/****** React Select and Date Picker CSS ***********/
.react-date-picker{
  padding:5px;
  &.is-invalid-border{
    border-color: #dc3545 !important;
  }
}
.react-date-picker__inputGroup{
  white-space: nowrap;
}
.react-date-picker__inputGroup__input::placeholder{
  color: $black-850;
  opacity: .8;
}
.react-date-picker--disabled{
  background-color: #e9ecef;
  border-color: $black-200;
}

.react-date-picker--disabled .react-date-picker__inputGroup__input::placeholder{
  color: $black-850;
  opacity: .3;
}
.react-date-picker__inputGroup__divider{
  color: $black-850;
  opacity: .8;
}
.react-date-picker--disabled .react-date-picker__inputGroup__divider{
  opacity: .3;
}
.react-date-picker__wrapper{
  border: none !important;
}
.react-select-container {
  &.is-invalid-border {
    .react-select__control{
      border-color: var(--bs-form-invalid-border-color) !important;
    }
    .react-date-picker{
      border-color: var(--bs-form-invalid-border-color) !important;
    }
    .react-select__control--is-focused{
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25) !important;
    }
  }
  .react-select__control {
    min-height: 40px;
    box-shadow: none;
    border-color:$black-200;
    &:hover {
      border-color:$black-200;
    }
    &.react-select__control--is-focused {
      border-color:var(--bs-body-color);
    }
    .react-select__indicators {
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__dropdown-indicator {
        svg {
          fill: #595959;
          transition: 0.1s ease-in;
        }
      }
    }
    &.react-select__control--menu-is-open {
      .react-select__dropdown-indicator {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  .react-select__menu {
    padding-top: 5px;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 8px 0px #0000000A;
    .react-select__menu-list {
      padding: 8px;
      height: auto;
      max-height: 180px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      .react-select__option {
        border-bottom: 1px solid #f2f2f2;
        &.react-select__option--is-selected {
          background-color: #f4f9fd;
          color: #0470a1;
        }
        &.react-select__option--is-focused {
          background-color: #f4f9fd;
          color: #0470a1;
        }
        &:hover {
          background-color: #f4f9fd;
        }
      }
    }
  }
}

/****** React Select CSS End ***********/

/************  React Multi Select CSS **********/
.react-select-multiselect{
  .react-select__value-container{
    -webkit-box-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 0px 3px;
    max-width: 120px;
    &::-webkit-scrollbar{
      height: 3px;
      width: 3px;
    }
    .react-select__multi-value{
      min-width: 80px;
      justify-content: space-between;
      background-color: #ffffff;
      border: 1px solid #e6e6e6;
      .react-select__multi-value__label{
        flex: 1;
        background-color: #ffffff;
      }
      .react-select__multi-value__remove{
        background-color: #ffffff;
        border-left: 1px solid #e6e6e6;
        &:hover{
          background-color: #FFBDAD;
        }
      }
    }
  }
  .react-select__menu{
    min-width: 200px;
    padding: 0 5px;
    .react-select__option{
      display: flex;
      align-items: center;
      padding: 15px 12px;
      border-bottom: 1px solid #f2f2f2;
      &:last-child{
        border-bottom: none;
      }
      input{
        width: 16px;
        height: 16px;
      }
      label{
        color: #333333;
        font-size: 14px;
      }
    }
  }
}



.rmsc.multi-select {
  max-width: 120px;
  min-width: 120px;
  .dropdown-heading {
    .dropdown-heading-value {
      font-size: 12px;
      font-weight: 500;
      color: $black-850;
    }
  }
  .dropdown-content {
    top: 120%;
    padding: 8px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 8px 8px 0px #00000014;
    .panel-content {
      border-radius: 0;
      background: 0;
      box-shadow: none;
    }
  }
  .select-item {
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    align-items: center;
    &:hover {
      background: #f4f9fd;
    }
    &.selected {
      background: #f4f9fd;
    }
  }
  .item-renderer {
    display: flex;
    align-items: center;
    input {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      margin: 0;
      margin-right: 8px;
    }
  }
  .search {
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    input {
      padding: 10px 8px;
      padding-left: 30px;
      background-image: url(../assets/images/search.svg);
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: 5px 12px;
      font-size: 1em;
      &:focus {
        background: transparent;
        border-color: #044d6e;
        background-image: url(../assets/images/search.svg);
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: 5px 12px;
      }
    }
  }
}

/************  React Multi Select CSS End **********/

/************* React Auto Search CSS ***********/
.autosearch {
  height: 40px !important;
  width: 100%;
  .wrapper {
    border: none !important;
    .dYrfwz {
      min-height: 40px;
    }
    &:focus-within {
      box-shadow: none !important;
    }
    &:hover {
      box-shadow: none !important;
    }
    input {
      font-size: 0.875rem;
      color: $black-850;
      font-weight: 500;
      line-height: 19px;
      height: 40px;
      border: 1px solid $black-200 !important;
      border-radius: 4px !important;
      padding: 10px 16px;
      &:focus-within {
        border-color: #044d6e !important;
      }
      &:hover {
        border-color: #044d6e !important;
      }
    }
    ul {
      border: 1px solid #f2f2f2;
      padding: 8px;
      max-height: 250px;
      overflow-y: auto;
      box-shadow: 0px 4px 4px 0px #0000000a;
      z-index: 9;
      &::-webkit-scrollbar {
        width: 4px;
      }
      li {
        border-bottom: 1px solid #f2f2f2;
        padding: 10px 6px;
        &:hover {
          background-color: #f4f9fd;
        }
      }
    }
  }
}
:root {
  --toastify-icon-color-success: #388F4B;
  --toastify-color-progress-success: #388F4B;
}
.Toastify__progress-bar--wrp {
  top:0
}
.Toastify__close-button--light {
  align-self: center;
  opacity: 1;
}
 .Toastify__toast--success{
  background:#F6FFF9;
  .Toastify__close-button > svg{

    fill:#388F4B;
  }
  .Toastify__close-button:hover {
    opacity: 0.5;
  }
 }
 .Toastify__toast--error{
  background:#FFF6F4;
  .Toastify__close-button > svg{

    fill:#D34343;
  }
  .Toastify__close-button:hover {
    opacity: 0.5;
  }
 }
 .Toastify__toast {
  width: 100%;
}
.Toastify__toast-container {
  width: auto; 
}
.Toastify__close-button{
margin-left: 10px;
}
/************* React Auto Search CSS End ***********/

table {
  margin-bottom: 0;
  td,th{
    border: none;
    white-space: nowrap;
  }
  th {
    background-color: #F2F9FF!important;
    font-size: 12px;
    font-weight: 600;
    color: $black-850 !important;
    line-height: 16px;
    padding: 14px 12px!important;
    text-transform: uppercase;
  }
  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    padding: 6px 12px!important;
    vertical-align: middle;
    color: $black-850 !important;
    span.negative-value {
      color: #D34343;
    }
  }
  tbody {
    td{
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    tr {
      td{
        &:last-child{
          overflow: initial;
        }
      }
    }
  }
  .dropdown-menu {
    .dropdown-item {
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      padding: 10px 12px;
      color: $black-850;
      &:last-child {
        border: none;
      }
    }
    &.show {
      border: 1px solid #f2f2f2;
      box-shadow: 0px 4px 4px 0px #0000000a;
    }
  }
}