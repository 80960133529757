.refrigerator-listing-wrapper {
    .listing-table-wrapper {
        border: 1px solid #f2f2f2;
        border-radius: 8px;
        margin-top: 16px;
        max-width: 100%;
        overflow: auto;
    }

    table {

        td,
        th {
            white-space: nowrap;
            border: none;
        }

        th {
            background-color: #fafbfc;
            font-size: 12px;
            font-weight: 600;
            color: #8c8c8c;
            line-height: 16px;
            padding: 14px 12px;
            text-transform: uppercase;
        }

        td {
            font-size: 14px;
            font-weight: 400;
            color: #8c8c8c;
            line-height: 19px;
            padding: 4px 12px;
            vertical-align: middle;
        }
    }
}