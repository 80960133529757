.add-user-profile-wrapper{
    .login-user-profile-header-wrapper{
        margin-bottom: 24px;
        h2{
            margin: 0;
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
            color: #333333;
            margin-bottom: 40px;
        }
        .login-user-header-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .login-user-box {
                display: flex;
                align-items: center;
                gap: 16px;
                .login-user-img{
                    position: relative;
                    width: 95px;
                    height: 95px;
                    border: 1px solid #044D6E;
                    padding: 5px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .dropdown-menu{
                        white-space: nowrap;
                        padding: 8px;
                        margin-top: -15px!important;
                        li{
                            padding: 12px 10px;
                            border-bottom: 1px solid #F2F2F2;
                            cursor: pointer;
                            &:last-child{
                                border-bottom: none;
                            }
                            svg{
                                margin-right: 10px;
                            }
                            &:last-child{
                                svg{
                                    margin-right: 17px;
                                }  
                            }
                            &:hover{
                                background-color: #F4F9FD;
                            }
                        }
                    }
                    img{
                        width: 80px;
                        height: 80px;
                    }
                    .edit-img-overlay {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        .img-edit-icon {
                            width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #ffffff;
                            border-radius: 50%;
                            svg{
                                width: 20px;
                            }
                        }
                    }
                }
                .login-user-title{
                    h4{
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 38px;
                        color: #333333;
                        margin: 0;
                    }
                    span{
                        background-color: #E6F7FE;
                        padding: 5px;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 19px;
                        color: #737373;
                        border-radius: 6px;
                    }
                }
            }
            .login-user-action-box{
                display: flex;
                align-items: center;
                gap: 24px;
                .login-user-action-btn{
                    font-size: 14px;
                    display: inline-flex;
                    align-items: center;
                    gap: 5px;
                    color: #333333;
                    position: relative;
                    cursor: pointer;
                    &::after{
                        content: "";
                        width: 1px;
                        height: 21px;
                        background-color: #d9d9d9;
                        position: absolute;
                        right: -12px;
                        top: 0px;
                    }
                    &:last-child{
                        &:after{
                            content: none;
                        }
                    }
                }
            }
        }
    }
    .add-user-box {
        border: 1px solid #F2F2F2;
        border-radius: 8px;
        padding: 16px;
        form{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 0 24px;
            min-height: 100%;
            .input-group {
                select.form-select{
                    max-width: 65px;
                    padding: 8px;
                }
            }
            label {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: #737373;
            }
            h5{
                font-size: 16px;
                color: #000000;
                font-weight: 600;
                line-height: 21px;
                margin: 0;
            }
            .input-with-button {
                display: flex;
                gap: 8px;
                justify-content: space-between;
                button {
                border: 1px solid #d9d9d9;
                background-color: transparent;
                min-width: max-content;
                border-radius: 4px;
                font-size: 14px;
                color: #737373;
                }
                button.use-unit-opt-btn {
                min-width: 40px;
                }
            }
            .full-width {
                grid-column: span 4;
            }
            button{
                min-width: 108px;
            }
            .item-checkbox-content {
                height: 100%;
                display: flex;
                width: 100%;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
}
#upload-avatar-modal{
    width: 750px;
    max-width: 750px;
    .img-upload-box {
        width: 100%;
        height: 240px;
        border: 1px dotted #B4E6FD;
        border-width: 3px;
        border-radius: 8px;
        background: #FAFBFC;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        input#avatar-upload-input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
        p{
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            margin: 0;
            margin-top: 16px;
        }
    }
    .file-type-hint{
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .modal--action--btn--wrapper {
        text-align: right;
        border-top: 1px solid #d9d9d9;
        padding-top: 20px;
    }
}