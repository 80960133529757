.prediction-graph-wrapper{
    margin-top: 20px;
    .prediction-header-box{
        margin-bottom: 20px;
        h2{
            font-size: 24px;
            line-height: 32px;
            color: #333333;
        }
        h5 {
            color: #595959;
            font-size: 16px;
            line-height: 21px;
            margin: 0;
        }
    }
    .graph {
        border: 1px solid #F2F2F2;
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 24px;
        h3 {
            font-size: 16px;
            line-height: 21px;
            font-weight: 700;
            color: #333333;
            margin: 0;
            border-bottom: 1px solid #f2f2f2;
            padding-bottom: 10px;
        }
    }
    .col{
        &:last-child{
            .graph{
                margin-bottom: 0;
            }
        }
    }
}
