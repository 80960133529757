.record-usage-item-box {
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 16px;
  .page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 16px;
    min-height: 100%;
        label {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #737373;
        .badge {
            cursor: pointer;
            padding: 2px 6px;
        }
        }
        .input-with-button {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
        > button {
            border: 1px solid #d9d9d9;
            background-color: transparent;
            min-width: max-content;
            border-radius: 4px;
            font-size: 14px;
            color: #737373;
            min-height: 40px;
        }
        .form-check {
            white-space: nowrap;
        }
        button.use-unit-opt-btn {
            min-width: 40px;
        }
        }
        .colspan2 {
        grid-column: span 2;
        }
        .full-width {
        grid-column: span 3;
        }
        button.btn {
        min-width: 108px;
        }
        .rowspan2 {
        grid-row: span 2;
        }
        .item-radio-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 0px;
        }
        .item-checkbox-content {
        height: 100%;
        display: flex;
        width: 100%;
        align-items: center;
        padding-top: 30px;
        }
        h5.form-section-title {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 16px;
        }
        .input-group {
            .form-select {
                max-width: 100px;
                padding: 10px 10px;
            }
        }
        #quentity-hint{
            font-size: 12px;
            line-height: 16px;
            color: #737373;
            cursor: pointer;
        }
        .record-item-info-wrapper{
            margin-top: 8px;
            border: 1px solid #F2F2F2;
            border-radius: 8px;
            >.form-section-title{
                padding: 16px;
                border-bottom: 1px solid #F2F2F2;
                margin-bottom: 0;
            }
            .record-item-info-container {
                padding: 16px;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 24px 24px;
                .form-section-title{
                    margin-bottom: 0;
                }
                .record-item-info-box {
                    label {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        color: #8C8C8C;
                    }
                    p {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 19px;
                        color: #333333;
                        margin: 0;
                    }
                    &.full-width {
                        grid-column: span 5;
                    }
                }
                .full-width {
                    grid-column: span 5;
                }
            }
            .record-item-info-table-wrapper{
                max-width: 100%;
                overflow-x: auto;
                &::-webkit-scrollbar {
                    width: 4px;
                    height: 4px;
                }
                table{
                    td,th {
                      white-space: nowrap;
                      border: none;
                    }
                    th{
                        background-color: #FAFBFC;
                        font-size: 12px;
                        font-weight: 600;
                        color: #8C8C8C;
                        line-height: 16px;
                        padding: 14px 12px;
                        text-transform: uppercase;
                    }
                    tbody{
                        th{
                            color: #333333;
                        }
                    }
                    td{
                        font-size: 14px;
                        font-weight: 400;
                        color: #8C8C8C;
                        line-height: 19px;
                        padding: 12px 12px!important;
                        vertical-align: middle;
                    }
                }
            }

        }
    }
}
#addpatientcmodal{
    max-width: 750px;
    .add-patient-box{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 16px;
        .full-width {
            grid-column: span 2;
        }
        .dob-date-input{
            background-image: url(../../assets/images/calender-icon.svg);
            background-size: 16px;
            background-position: 95% 12px;
            background-repeat: no-repeat;
        }
    }

}
#quantityPopover{
    border: none;
    max-width: 350px;
    box-shadow: 0px 0px 4px 0px #00000029;
    &.bs-popover-bottom{
        .popover-arrow{
            &::before{
                border-bottom-color: #044d6e;
            }
            &::after{
                border-bottom-color: #044d6e;
            }
        }
    }
    .popover-body {
        padding: 0;
        border-radius: 0.5rem;
        overflow: hidden;
        table{
            margin-bottom: 0;
            td,th {
            white-space: nowrap;
            }
            th{
                background-color: #044d6e;
                font-size: 12px;
                font-weight: 600;
                color: #ffffff;
                line-height: 16px;
                padding: 14px 16px;
                text-transform: uppercase;
            }
            td{
                font-size: 14px;
                font-weight: 400;
                color: #8C8C8C;
                line-height: 19px;
                padding: 12px 16px;
                border-right: none;
                vertical-align: middle;
            }
        }
    }

}
.autosearch {

.wrapper{
    z-index: 1 !important;
}
}
.input-group > .form-control:not(:focus).is-invalid{
    z-index: 0 ;
}
.is-invalid-auto-search {
&.autosearch .wrapper input{
    border-color: #dc3545 !important;
}}