.patients-master-profile-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    .profile-title-status-box {
        display: flex;
        align-items: center;
        gap: 30px;
        h2{
            font-size: 28px;
            font-weight: 600;
            line-height: 38px;
            color: #044D6E;
            position: relative;
            margin: 0;
            &::after{
                content: none;
            }
        }
        .status-box{
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 4px 8px;
            border-radius: 6px;
            span.status-circle {
                display: inline-block;
                width: 11px;
                height: 11px;
                background: transparent;
                border-radius: 50%;
            }
            span.status-text {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
            }
            &.pending{
                background-color: #FFF9DB;
                span.status-circle{
                    background-color: #FFA500;
                }
                span.status-text{
                    color: #FFA500;
                }
            }
            &.active{
                background-color: #EBFBEE;
                span.status-circle{
                    background-color: #388F4B;
                }
                span.status-text{
                    color: #388F4B;
                }
            }
            &.inactive{
                background-color: #FFF5F5;
                span.status-circle{
                    background-color: #D34343;
                }
                span.status-text{
                    color: #D34343;
                }
            }
        }
    }
    .profile-actions-box {
        display: flex;
        align-items: center;
        gap: 10px;
        button {
            border: 1px solid;
            border-color: transparent;
            border-radius: 4px;
            padding: 10px 12px;
            font-size: 14px;
            font-weight: 500;
            display: inline-flex;
            gap: 8px;
            background: transparent;
            line-height: 1;
            &.reject-profile-btn{
                background: #FFF5F5;
                color: #D34343;
                border-color: #D34343;
            }
            &.approve-profile-btn{
                background: #EBFBEE;
                color: #17823F;
                border-color: #17823F;
            }
        }
        .dropdown-menu {
            .dropdown-item{
                border-bottom: 1px solid #F2F2F2;
                display: flex;
                gap: 8px;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;
                padding: 10px 12px;
                color: #333333;
                &:last-child{
                    border: none;
                }
            }
            &.show{
                border: 1px solid #F2F2F2;
                box-shadow: 0px 4px 4px 0px #0000000A;
            }
        }
        .load-uses-btn {
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
            color: #595959;
            cursor: pointer;
        }

    }
}

#statusmodal{
    .modal-content{
        padding: 20px;
        .modal-header{
            border: none;
        }
        .modal-body{
            padding: 0;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            img{
                width: 220px;
                margin-bottom: 30px;
            }
            h4{
                font-size: 20px;
                line-height: 27px;
                font-weight: 700;
                text-align: center;
                color: #333333;
            }
            p{
                font-size: 14px;
                line-height: 19px;
                font-weight: 400;
                text-align: center;
                color: #595959;
            }
        }
        .modal-footer{
            border: none;
            justify-content: center;
        }
    }
}