@import '../../styles/variables';
.login-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0;
    height: 100%;  
    .login-content{
        max-width: 400px;
        h4{
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.2;
            letter-spacing: 0.02em;
            margin-bottom: 10px;
        }
        p{
            color: #737373;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 32px;
            a{
                color: #737373;
                font-weight: 600;
                font-size: 16px;
            }
        }
        label.form-label {
            font-size: 12px;
            font-weight: 500;
            color: #737373;
        }
        .form-control{
            font-size: 14px;
            height: 40px;
            padding: 8px 16px;
            .password-field-box{
                position: relative;
                .form-control{
                    padding-right: 35px;
                }
                i {
                    position: absolute;
                    right: 10px;
                    bottom: 5px;
                    cursor: pointer;
                }
            }
        }
        .btn-link{
            color: $primary-800;
            text-align: right;
            text-decoration: none;
            font-size: 16px;
            font-weight: 600;
            padding: 0;
        }
    }
    .login-content img {
        margin-bottom: 64px;
    }
    .copy-text{
        font-size: 14px;
        font-weight: 400;
        color: #8C8C8C;
    }

}
.login-image-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 55px 95px;
    height: 100%;
    h1{
        margin: 0;
        font-size: 3rem;
        letter-spacing: 0.02em;
        font-weight: 700;
        line-height: 65px;
        color: #333333;
        margin-bottom: 20px;
    }
    h4{
        margin: 0;
        font-size: 32px;
        font-weight: 400;
        line-height: 43px;
        color: #333333;
        letter-spacing: 0.02em;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #333333;
    }
    .url-link-box{
      margin-top: 50px;
    }
}
#password-link-success-modal{
    .modal-header {
        border: none;
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        img{
            margin-bottom: 30px;
        }
        h5{
            font-size: 20px;
            font-weight: 700;
            color: #333333;
            line-height: 27px;
        }
        p{
            font-size: 14px;
            font-weight: 400;
            color: #595959;
            line-height: 19px;
            &.return-text{
                font-size: 16px;
                font-weight: 600;
                color: #737373;
                line-height: 21px;
                margin-top: 20px;
            }
        }
        button{
            font-size: 14px;
        }
    }
}
.display-center{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; 
    color: red;
}
.form-control.is-invalid {
    background-image: none;
}