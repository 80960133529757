.add-loc-box {
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    padding: 16px;
    form{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 24px;
        min-height: 100%;
        label {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #737373;
        }
        .input-with-button {
            display: flex;
            gap: 8px;
            justify-content: space-between;
            button {
              border: 1px solid #d9d9d9;
              background-color: transparent;
              min-width: max-content;
              border-radius: 4px;
              font-size: 14px;
              color: #737373;
            }
            button.use-unit-opt-btn {
              min-width: 40px;
            }
          }
        .full-width {
            grid-column: span 2;
        }
        button{
            min-width: 108px;
        }
        .item-checkbox-content {
            height: 100%;
            display: flex;
            width: 100%;
            align-items: center;
            padding-top: 30px;
        }
    }
}