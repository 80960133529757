@import '../../styles/variables';

.contact-us-wrapper {
    .contact-info-main {
        .card-header {
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            color: $primary-color;
        }

        .card-text {
            font-size: 14px;
            line-height: normal;

            a {
                color: $black-850;
            }
        }
    }

    .contact-form {
        .form-label {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #8C8C8C;
        }
    }
}