.athena-setting-box-container {
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    padding: 16px;
    form{
        .athena-setting-box {
            display: grid;
            grid-template-columns: 300px calc(100% - 324px);
            align-items: center;
            gap: 24px;
            margin-bottom: 24px;
            &.has-sub-settings{
                align-items: self-start;
            }
            .athena-setting-content{
                .rmsc{
                    &.multi-select {
                        width: 100%;
                        max-width: 100%;
                    }
                    .clear-selected-button{
                        min-width: auto;
                    }
                }
                .main-setting-box{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    .react-select-container {
                        width: 300px;
                    }
                }
            }
            .sub-setting-box-container {
                display: flex;
                flex-wrap: wrap;
                margin-top: 0px;
                margin-bottom: 20px;
                border: 1px solid #D9D9D9;
                padding: 15px;
                border-radius: 4px;
                gap: 16px;
                >div{
                    flex: 1;
                }
                .sub-setting-box{
                    width: 50%;
                }
                .main-setting-box{
                    min-width: 100%;
                    margin-bottom: -10px;
                    label{
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
                .sub-setting-box {
                    label{
                        font-size: 12px!important;
                        line-height: 16px!important;
                        font-weight: 500!important;
                        color: #737373!important;
                    }
                    .form-check-inline{
                        .form-check-label{
                            color: #333333!important;
                        }
                    }
                }
            }
        }
        label {
            font-size: 12px;
            font-weight: 500;
            line-height: 19px;
            color: #333333;
        }
        .form-control{
            color: #333333;
        }
        input[type="checkbox"]{
            width: 18px!important;
            height: 18px!important;
            border: 2px solid #BFBFBF!important;
            &:checked[type=checkbox]{
                border-color: #0470a1!important;
            }
        }
        .input-with-button {
            display: flex;
            gap: 8px;
            justify-content: space-between;
            button {
              border: 1px solid #d9d9d9;
              background-color: transparent;
              min-width: max-content;
              border-radius: 4px;
              font-size: 14px;
              color: #737373;
            }
            button.use-unit-opt-btn {
              min-width: 40px;
            }
          }
        .full-width {
            grid-column: span 3;
        }
        button{
            min-width: 108px;
        }
        .item-checkbox-content {
            height: 100%;
            display: flex;
            width: 100%;
            align-items: center;
            padding-top: 30px;
        }
        .error-msg {
            color: red;
            margin-top: -20px;
            font-size: 14px;
        }
        .info-msg {
            margin-top: -20px;
            span{
                color: green;
                font-size: 14px;
            }
         
        }
        .red-border {
            border: 1px solid #dc3545;

            .react-select__control.react-select__control--is-focused {
            border: none

            }
        }
    }
}
.react-select-multiselect .react-select__value-container{
    max-width: 100%;
}
.max-width-300{
    max-width: 300px;
}