.add-item-box {
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 16px;
  .page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 16px;
    min-height: 100%;
    .react-date-picker__inputGroup__divider {
      padding-right: 0.07rem;
    }
    label {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #737373;
      span.badge {
          cursor: pointer;
      }
    }
    .input-with-button {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: flex-start;
      input.exp-date-input{
        background-image: url(../../../assets/images/calender-icon.svg);
        background-size: 16px;
        background-position: 95% 12px;
        background-repeat: no-repeat;
      }
      >button {
        border: 1px solid #d9d9d9;
        background-color: transparent;
        min-width: max-content;
        border-radius: 4px;
        font-size: 14px;
        color: #737373;
        min-height: 40px;
      }
      .form-check{
        white-space: nowrap;
        &.isneverexpire{
          label{
            font-size: 14px;
          }
          #isNeverExpire{
            width: 18px;
            height: 18px;
          }
        }
      }
      button.use-unit-opt-btn {
        min-width: 40px;
      }
    }
    .colspan2 {
      grid-column: span 2;
    }
    .full-width {
      grid-column: span 3;
    }
    button.btn {
      min-width: 108px;
    }
    .rowspan2 {
      grid-row: span 2;
    }
    .item-radio-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px 0px;
    }
    .item-checkbox-content {
      height: auto;
      display: flex;
      width: 100%;
      align-items: center;
      padding-top: 38px;
    }
    h5.form-section-title {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 16px;
    }
  }
}
#useunitmodal {
  max-width: 750px;
  button.add-unit-btn, button.blue-btn {
    border: 1px solid #d9d9d9;
    min-width: 70px;
    height: 40px;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    white-space: nowrap;
    box-shadow: 0px 1px 1px 0px #00000014;
}

  button.add-unit-btn {
    background-color: transparent;
    color: #737373;
  }

  button.blue-btn {
    background-color: #044d6e;
    color: white;
  }
  tbody {
    tr {
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .dropdown-menu {
    min-width: 135px;
    .dropdown-item {
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      padding: 10px 12px;
      color: #333333;
      &:last-child {
        border: none;
      }
    }
    &.show {
      border: 1px solid #f2f2f2;
      box-shadow: 0px 4px 4px 0px #0000000a;
    }
  }
  .use-unit-item-table-wrapper {
      max-height: 300px;
      overflow-y: auto;
      .table{
        thead{
          th{
            white-space: nowrap;
            position: sticky;
            top: 0;
            z-index: 1;
          }
        }
      }
  }
}
.add-unit-item-box {
  form {
    display: grid;
    grid-template-columns: 280px 280px 90px;
    gap: 0px 16px;
    position: relative;
    z-index: 2;
    .full-width {
      grid-column: span 3;
    }
  }
}
.lookupbtn {
  color: #595959;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  text-decoration: none;
  svg {
    margin-right: 8px;
  }
}
#lookupmodal {
  .modal-body {
    padding: 1.5rem;
  }
  .add-item-box {
    border: none;
    padding: 0;
    form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 24px;
      min-height: 100%;
      .item-radio-content {
        grid-column: span 2;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(3, auto);
      }
      .barcodebox {
        display: flex;
        gap: 10px;
        button {
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          background: transparent;
          color: #a6a6a6;
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
          &:hover {
            border-color: #044d6e;
            color: #044d6e;
          }
        }
      }
    }
    .full-width {
      grid-column: span 2;
    }
  }
}
.invalid-feedback{
  display: block;
}
.react-select__placeholder{
  font-size: 0.875rem;
}
.custom-tooltip {
  background-color: #333;
  color: #fff; 
  font-size: 0.875rem; 
  border-radius: 4px; 

  .tooltip-inner {
    text-align: left;
  }
}