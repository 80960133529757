div#notification-drop {
    display: none;
}

#header{
    min-height: 80px;
    padding: 0 16px;
}
.logo-box {
    width: 250px;
    display: flex;
    align-items: center;
    color: #0734A9;
}
.sidebarOpen .logo-box {
    width: 60px;
}
.searchbar {
    display: flex;
    align-items: center;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    padding: 0 10px;
    line-height: 1;
    min-width: 400px;
    background: #ffffff;
}
.searchbar form{
    width: 100%;
}
.searchbar input {
    border: none;
    &:focus, &:active{
        box-shadow: none;
    }
}

.sidebar-header{
    display: flex;
    align-items: center;
    max-width: 250px;
}
.logo-text{
    font-size: 25px;
    font-weight: bold;
    padding-left: 5px;
    overflow: hidden;
    .svg-box{
        svg {
            width: 90%;
        }
    } 
}
.sidebarOpen span.logo-text {
    display: none;
}
.rightnavbar {
    display: flex;
    align-items: center;
    .dropdown{
        cursor: pointer;
    }
    .dropdown-menu{
        top: 20px!important;
        right: 0 !important;
        border-radius: 4px;
        border-color: #F2F2F2;
        box-shadow: 0px 4px 4px 0px #0000000A;
    }
}
.rightnavbar .dropdown:first-child{
    padding-right: 10px;
}
.rightnavbar .dropdown:nth-child(2){
    padding-left: 10px;
    border-left: none;
}
.rightnavbar .dropdown:first-child .dropdown-toggle::after{
    content: none;
}
.login-user-profile {
    display: flex;
    align-items: center;
    gap: 5px;
}
.login-user-profile img {
    width: 30px;
    height: 30px;
    margin-right: 3px;
}
.user-info-box h4{
    margin: 0;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    color: #595959;
}
.user-info-box h6{
    margin: 0;
    font-size: 10px;
    font-weight: 500;
    color: #333333;
}
.rightnavbar a.dropdown-item {
    display: flex;
    align-items: center;
    &:hover{
        background-color: #e6f7fe;
        color: #044d6e;
    }
    &:focus{
        color: #044d6e;
        background-color: #e6f7fe;
    }
    &:active{
        color: #044d6e;
    }
    i{
        margin-right: 8px;
    }
}
.rightnavbar a.dropdown-item h5{
    font-size: 14px;
    margin: 0;
}
.rightnavbar a.dropdown-item img{
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
.sidebar-toggle-button{
    cursor: pointer;
}

.dropdown-toggle::after{
    content: none!important;
}
