@media (min-width: 1200px){
    .modal-xl {
        width: 1192px;
    }
    .filter-item-detail-wrapper{
        .filter-item-detail-table-wrapper{
            height: 275px;
            .table{
                thead{
                    th {
                        position: sticky;
                        top: 0;
                        z-index: 1;
                    }
                } 
            } 
        }
    } 
}
@media (min-width: 1400px){

}
@media (min-width: 1440px){
    .modal-xl {
        width: 1344px;
    }
    .content-wrapper {
        height: calc(100vh - 80px);
    }
    .listing-table-wrapper{
        .table{
          thead{
            th {
              position: sticky;
              top: 0;
              z-index: 1;
            }
          } 
        }
    }
    .filter-item-detail-wrapper{
        .filter-item-detail-table-wrapper{
            height: 450px;
            .table{
                thead{
                    th {
                        position: sticky;
                        top: 0;
                        z-index: 1;
                    }
                } 
            } 
        }
    } 
}
@media(min-width: 1600px){
    .listing-searchbar-wrapper {
        min-width: 500px;
        .listing-searchbar-item-wrapper {
          .listing-searchbar-item-box{
            input{
              min-width: 320px;
            }
          }
        }
    }
    .filter-item-detail-wrapper{
        .filter-item-detail-table-wrapper{
            height: 420px;
            .table{
                thead{
                    th {
                        position: sticky;
                        top: 0;
                        z-index: 1;
                    }
                } 
            } 
        }
    } 
}
@media(min-width: 1920px){
    .filter-item-detail-wrapper{
        .filter-item-detail-table-wrapper{
            height: 550px;
            .table{
                thead{
                    th {
                        position: sticky;
                        top: 0;
                        z-index: 1;
                    }
                } 
            } 
        }
    } 
}
@media(min-width: 1921px){
    .login-content-wrapper {
        justify-content: center;
    }
}