.report-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  * {
    margin: 0;
    padding: 0;
  }
  .report-header-content {
    h5 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      color: #333333;
    }
    p {
      font-size: 16px;
      line-height: 21px;
      font-weight: 600;
      color: #595959;
    }
  }
  .report-header-logo-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    .report-header-logo-box {
      position: relative;
      &.sf-logo-box {
        img {
          width: 140px;
        }
      }
      &:after {
        content: "";
        width: 1px;
        height: 26px;
        background-color: #d9d9d9;
        position: absolute;
        right: -10px;
        top: 3px;
      }
      &:last-child:after {
        content: none;
      }
    }
  }
}
