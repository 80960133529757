.listing-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .listing-title-info{
        h2{
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: #333333;
            margin: 0;
        }
        p{
            margin: 0;
            font-size: 12px;
            line-height: 16px;
            color: #A6A6A6;
        }
    }
    .listing-action-box {
        display: flex;
        gap: 20px;
        .action-box{
            padding-right: 20px;
            position: relative;
            &:after {
                content: '';
                width: 1px;
                background: #d9d9d9;
                height: 26px;
                position: absolute;
                right: 0;
                top: 4px;
            }
        }
    }
}
.listing-action-box {
    display: flex;
    gap: 20px;
    .action-box{
        padding-right: 0px;
        position: relative;
        &:after {
            content: none;
        }
    }
    .import-export-box-wrapper{
        display: flex;
        align-items: center;
        gap: 32px;
        margin-right: 16px;
        .import-export-btn{
            cursor: pointer;
            position: relative;
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            svg{
                margin-right: 6px;
            }
            &:after{
                content: "";
                width: 1px;
                background: #d9d9d9;
                height: 26px;
                position: absolute;
                right: -16px;
                top: 0px;
            }
        }
    }
}