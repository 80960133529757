.modal--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    overflow-y: auto;
    .modal--content {
        width: 100%;
        max-width: 450px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        .modal--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 24px;
            h4{
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;
                margin: 0;
            }
            span.close--modal{
                font-size: 20px;
                display: inline-flex;
                width: 20px;
                height: 20px;
                align-items: center;
                justify-content: center;
                color: #737373;
                cursor: pointer;
            }
        }
        .modal--body {
            padding: 24px;
            label {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: #737373;
            }
            .btn{
                min-width: 108px;
            }
            .calender-box{
                button{
                    min-width: auto;
                }   
            }
            .modal--content--wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                text-align: center;
                h5{
                    font-size: 20px;
                    line-height: 27px;
                    font-weight: 700;
                    color: #333333;
                    margin: 0;
                }
                p{
                    font-size: 14px;
                    line-height: 19px;
                    font-weight: 500;
                    color: #333333;
                    margin: 0;
                }
                .modal--inputbox{
                    width: 100%;
                    text-align: left;
                }
            }
            .modal--action--btn--wrapper {
                margin-top: 24px;
                text-align: center;
                width: 100%;
                button{
                    margin-right: 16px;
                    &:last-child{
                        margin-right: 0;
                    }
                }                
            }
        }
        
        .modal--footer {
            display: flex;
            justify-content: flex-end;
        }
        table{
            margin-bottom: 0;
            td,th {
              // white-space: nowrap;
              border: none;
            }
            th{
                background-color: #FAFBFC;
                font-size: 12px;
                font-weight: 600;
                color: #8C8C8C;
                line-height: 16px;
                padding: 14px 12px;
                text-transform: uppercase;
            }
            tbody{
                th{
                    color: #333333;
                }
                tr{
                    td{
                        &:last-child{
                            text-align: right;
                        }
                    }
                }
            }
            td{
                font-size: 14px;
                font-weight: 400;
                color: #8C8C8C;
                line-height: 19px;
                padding: 12px 12px!important;
                vertical-align: middle;
            }
          }
    }
    #ordermngtDetailModal{
        .modal--header{
            padding: 16px 12px;
        }
        .modal--body{
            padding: 0!important;
        }
        .modal--footer{
            padding: 24px;
            button{
                min-width: 108px;
                margin-right: 16px;
                    &:last-child{
                        margin-right: 0;
                    }
            }
        }
    }
}
.customeModalOpen{
    overflow: hidden;
}
.btn-search{
    background-color: #d9d9d9;
}