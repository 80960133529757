.inventory-profile-wrapper{
  .profile-detail-box-wrapper{
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    padding: 16px 0;
    .inventory-profile-detail-box {
      #inventory-profile-tab {
        padding: 0 16px;
        .nav-link {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          color: #737373;
          border: none;
          &:hover,
          &:focus {
            border-color: transparent;
          }
          &.active {
            font-weight: 600;
            color: #044d6e;
            border: none;
            border-bottom: 2px solid #044d6e;
          }
        }
      }
      .tab-content {
        padding: 0 0px;
        .inventory-tab-head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;
          h4 {
            margin: 0;
            font-size: 12px;
            line-height: 16px;
            color: #8c8c8c;
          }
          .inventory-tab-action-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;
            .inventory-tab-action-box {
              font-size: 14px;
              line-height: 19px;
              font-weight: 600;
              color: #595959;
              position: relative;
              cursor: pointer;
              .dropdown-menu {
                border-radius: 4px;
                border-color: #F2F2F2;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0392156863);
                li {
                  position: relative;
                  font-size: 14px;
                  color: #595959;
                  font-weight: 400;
                  padding: 8px 12px;
                  display: flex;
                  align-items: center;
                  &:hover{
                    background-color: #e6f7fe;
                    color: #044d6e;
                  }
                  &:after{
                    content: "";
                    width: 80%;
                    height: 1px;
                    background-color: #d9d9d9;
                    position: absolute;
                    left: 10%;
                    bottom: 0;
                    opacity: 0.5;
                  }
                  &:last-child:after{
                    content: none;
                  }
                }
              }
              &:after {
                content: "";
                width: 1px;
                height: 16px;
                background-color: #f2f2f2;
                position: absolute;
                right: -8px;
                top: 3px;
              }
              &:last-child:after{
                content: none;
              }
            }
            .inventory-tab-action-dropdown {
              display: flex;
              gap: 5px;
              align-items: center;
              .dropdown-menu {
                min-width: 6rem;
                padding: 0;
                left: -28px !important;
                top: 5px !important;
                .dropdown-item {
                  border-bottom: 1px solid #d9d9d9;
                  padding: 8px 8px;
                  font-size: 14px;
                  line-height: 19px;
                  color: #333333;
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }
            .inventory-tab-action-trans-report {
              svg {
                margin-right: 6px;
              }
            }
            .inventory-tab-more-action-wrapper {
                display: flex;
                gap: 12px;
                align-items: center;
            }
          }
        }
        .inventory-profile-table-wrapper{
            margin-top: 18px;
            td,th {
                white-space: nowrap;
                border: none;
            }
            th{
                background-color: #FAFBFC;
                font-size: 12px;
                font-weight: 600;
                color: #8C8C8C;
                line-height: 16px;
                padding: 14px 12px;
                text-transform: uppercase;
            }
            tbody{
                th{
                    color: #333333;
                }
                tr{
                    td{
                        &:last-child{
                            text-align: right;
                        }
                    }
                }
            }
            td{
                font-size: 14px;
                font-weight: 400;
                color: #8C8C8C;
                line-height: 19px;
                padding: 4px 12px;
                vertical-align: middle;
            }
            .dropdown-menu {
                .dropdown-item{
                    border-bottom: 1px solid #F2F2F2;
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 19px;
                    padding: 10px 12px;
                    color: #333333;
                    &:last-child{
                        border: none;
                    }
                }
                &.show{
                    border: 1px solid #F2F2F2;
                    box-shadow: 0px 4px 4px 0px #0000000A;
                }
            }
        }
      }
      span.barcode-status-circle {
        margin-right: 8px;
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #388F4B;
      }
      .invt-overview-content-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        padding: 0 16px;
        .invt-overview-section-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &.full-width {
            grid-column: span 3;
          }
          h5 {
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 0;
          }
          .inventory-actions-box-wrapper {
            display: flex;
            align-items: center;
            gap: 32px;
            .print-barcode-btn-box {
              display: flex;
              align-items: center;
              cursor: pointer;
              span.print-barcode-btn {
                font-size: 14px;
                display: inline-flex;
                align-items: center;
                gap: 5px;
                color: #333333;
                position: relative;
                &::after {
                  content: "";
                  width: 1px;
                  height: 21px;
                  background-color: #d9d9d9;
                  position: absolute;
                  right: -16px;
                  top: 0px;
                }
              }
            }
            .inventory-actions-box {
              display: flex;
              gap: 12px;
              span.invt-action-btn {
                cursor: pointer;
                width: 24px;
                height: 24px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
        .invt-overview-info-box {
          label {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #8c8c8c;
          }
          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: #333333;
            margin: 0;
            padding: 8px 16px;
            border: 1px solid #D9D9D9;
            border-radius: 4px;
            margin-top: 8px !important;
            min-height: 36px;
          }
          &.full-width {
            grid-column: span 3;
          }
        }
      }
    }
  }
}

.barcodes-list-box ul li {
  line-height: 1.5rem;
  padding: 16px 0;
  border-bottom: 1px solid #F2F2F2;
}

#mngt-order-detail-modal{
  table{
    th {
      border: 1px solid #dee2e6;
    }
    td {
      border: 1px solid #dee2e6;
    }
  } 
}

#trans-report-modal{
  .modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      h4{
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
        color: #333333;
        margin: 0;
      }
      .trans-report-action-wrapper {
          display: flex;
          align-items: center;
          gap: 24px;
          .trans-report-action-box{
            position: relative;
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            cursor: pointer;
            &.export-report-opt-box{
              display: flex;
              align-items: center;
              gap: 5px;
            }
            &:after {
                content: "";
                width: 1px;
                height: 21px;
                background-color: #f2f2f2;
                position: absolute;
                right: -12px;
                top: 3px;
            }
            &:last-child:after{
              content: none;
            }
            .dropdown-menu{
              &.show{
                top: 14px !important;
                min-width: 90px;
                border-color: #F2F2F2;
                box-shadow: 0px 4px 4px 0px #0000000A;
                border-radius: 4px;
              }
              .dropdown-item{
                font-size: 14px;
                line-height: 19px;
                color: #333333;
                border-bottom: 1px solid #F2F2F2;
                padding: 8px;
                img{
                  width: 14px;
                  height: 14px;
                  margin-right: 8px;
                }
                &:last-child{
                  border-bottom: none;
                }
              }
            }
          } 
      }
  }
  .modal-body{
    background-color: #F5F5F5;
    padding: 0;
    .trans-report-content-wrapper {
      background-color: #ffffff;
      padding: 24px 0;
      border: 1px solid #F2F2F2;
      border-radius: 8px;
      max-width: 90%;
      margin: 40px auto;
      .trans-report-content-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 24px;
        border-bottom: 1px solid #F2F2F2;
        *{
          margin: 0;
          padding: 0;
        }
        .header-content{
          h5{
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
            color: #333333;
          }
          p{
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            color: #8C8C8C;
          }
        }
        .header-logo-wrapper{
          display: flex;
          align-items: center;
          gap: 20px;
          .header-logo-box{
            position: relative;
            &.sf-logo-box{
              img{
                width: 140px;
              }
            }
            &:after {
                content: "";
                width: 1px;
                height: 26px;
                background-color: #d9d9d9;
                position: absolute;
                right: -10px;
                top: 3px;
            }
            &:last-child:after{
              content: none;
            }
          }
        }
      }
      .trans-report-detail-box-wrapper{
        padding: 16px 24px;
        h5{
          font-size: 20px;
          font-weight: 600;
          line-height: 27px;
          color: #333333;
        }
        .trans-report-detail-box-container {
            display: flex;
            align-items: center;
            gap: 48px;
            .trans-report-detail-box{
              position: relative;
              label{
                font-size: 14px;
                line-height: 19px;
                font-weight: 500;
                color: #8C8C8C;
              }
              p{
                font-size: 16px;
                line-height: 21px;
                font-weight: 500;
                color: #333333;
                margin: 0;
              }
              &:after{
                content: "";
                width: 1px;
                height: 36px;
                background-color: #f2f2f2;
                position: absolute;
                right: -24px;
                top: 8px;
              }
              &:last-child:after{
                content: none;
              }
            }
        }
        .trans-report-table-wrapper{
          margin-top: 16px;
          border: 1px solid #F2F2F2;
          border-radius: 8px;
          table{
            margin-bottom: 0;
            td,th {
              // white-space: nowrap;
              border: none;
            }
            th{
                background-color: #FAFBFC;
                font-size: 12px;
                font-weight: 600;
                color: #8C8C8C;
                line-height: 16px;
                padding: 14px 12px;
                text-transform: uppercase;
            }
            tbody{
                th{
                    color: #333333;
                }
            }
            td{
                font-size: 14px;
                font-weight: 400;
                color: #8C8C8C;
                line-height: 19px;
                padding: 12px 12px;
                vertical-align: middle;
            }
          }
        }
      }
  }
  }
}
.disable-label-field{
  background-color: var(--bs-secondary-bg)
}
.status-box{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  border-radius: 6px;
  span.status-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
  }
  &.pending{
      background-color: #FFF9DB;
      span.status-circle{
          background-color: #FFA500;
      }
      span.status-text{
          color: #FFA500;
      }
  }
  &.active{
      background-color: #EBFBEE;
      span.status-circle{
          background-color: #388F4B;
      }
      span.status-text{
          color: #388F4B;
      }
  }
  &.inactive{
      background-color: #FFF5F5;
      span.status-circle{
          background-color: #D34343;
      }
      span.status-text{
          color: #D34343;
      }
  }
}