.order-page-title-box{
    margin-bottom: 24px;
    h2 {
        font-size: 28px;
        font-weight: 600;
        line-height: 38px;
        color: #044D6E;
        position: relative;
        margin: 0;
    }
} 
.order-detail-box-wrapper{
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    padding: 16px;
    .profile-detail-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        .profile-info-box {
            height: auto;
            label {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: #8C8C8C;
            }
            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;
                color: #333333;
                margin: 0;
            }
            &.full-width {
                grid-column: span 3;
            }
        }
        .order-action-box {
            padding-top: 25px;
            display: flex;
            align-items: center;
            gap: 24px;
            .order-action-btn {
            position: relative;
            border: none;
            background: none;
            font-size: 14px;
            line-height: 19px;
            color: #333333;
            display: flex;
            gap: 8px;
            align-items: center;
            cursor: pointer;
            &:after{
                content: '';
                width: 1px;
                height: 20px;
                background-color: #D9D9D9;
                position: absolute;
                right: -10px;
                top: 3px;
            }
            &:last-child{
                &:after{
                content: none;
                }
            }
            }
        }
    }
}
.inventory-order-table-wrapper{
    margin-top: 24px;
    .listing-table-wrapper {
      border: 1px solid #f2f2f2;
      border-radius: 8px;
      max-width: 100%;
      overflow: auto;
    }
  }
  .css-1nmdiq5-menu{
    z-index: 2;
  }

.profile-info-date{
            p{
              font-size: 12px;
              margin-top: 20px;
              text-align: right;
            }
        }