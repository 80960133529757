.inventory-profile-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  .inventory-title-status-box {
    display: flex;
    align-items: center;
    gap: 30px;
    h2 {
      font-size: 28px;
      line-height: 38px;
      color: #044d6e;
      position: relative;
      margin: 0;
      &::after {
        content: "";
        width: 1px;
        background-color: #d9d9d9;
        height: 21px;
        position: absolute;
        top: 9px;
        right: -15px;
      }
    }
    .status-box {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 4px 8px;
      border-radius: 6px;
      span.status-circle {
        display: inline-block;
        width: 11px;
        height: 11px;
        background: transparent;
        border-radius: 50%;
      }
      span.status-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
      &.pending {
        background-color: #fff9db;
        span.status-circle {
          background-color: #ffa500;
        }
        span.status-text {
          color: #ffa500;
        }
      }
      &.active {
        background-color: #ebfbee;
        span.status-circle {
          background-color: #388f4b;
        }
        span.status-text {
          color: #388f4b;
        }
      }
      &.inactive {
        background-color: #fff5f5;
        span.status-circle {
          background-color: #d34343;
        }
        span.status-text {
          color: #d34343;
        }
      }
    }
  }
}

#statusmodal {
  .modal-content {
    padding: 20px;
    .modal-header {
      border: none;
    }
    .modal-body {
      padding: 0;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      img {
        width: 220px;
        margin-bottom: 30px;
      }
      h4 {
        font-size: 20px;
        line-height: 27px;
        font-weight: 700;
        text-align: center;
        color: #333333;
      }
      p {
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        text-align: center;
        color: #595959;
      }
    }
    .modal-footer {
      border: none;
      justify-content: center;
    }
  }
}
