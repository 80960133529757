.dashboard-wrapper{
    .dashboard-header-box{
        margin-bottom: 24px;
        h2 {
            margin: 0;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .dash-overview-wrapper{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 16px;
        margin-bottom: 16px;
        .dash-overview-box{
            position: relative;
            border: 1px solid #F2F2F2;
            border-radius: 8px;
            padding: 16px 8px;
            .dash-overview-title{
                display: flex;
                align-items: center;
                gap: 6px;
                span{
                    font-size: 12px;
                    font-weight: 500;
                    color: #737373;
                }
            }
            .dash-overview-count{
                padding-left: 30px;
                font-size: 24px;
                font-weight: 700;
                color: #333333;
                line-height: 32px;
            }
        }
    }
    .dashboard-grid-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        .dashboard-grid {
            border: 1px solid #F2F2F2;
            border-radius: 8px;
            padding: 24px;
            .dashboard-grid-title-box{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 30px;
                .select-opt-box {
                    display: flex;
                    gap: 16px;
                    .react-select-container{
                        min-width: 120px;
                        max-width: 150px;
                        .react-select__control{
                            border: none;
                            .react-select__single-value{
                                font-size: 14px;
                                color: #333333;
                                font-weight: 600;
                            }
                            .react-select__indicator{
                                padding: 0;
                            }
                        }
                        &:last-child{
                            .react-select__menu{
                                min-width: 400px;
                                left: initial;
                                right: 0;
                            }
                        }

                    } 
                }
                h3{
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: 700;
                    color: #333333;
                    margin: 0;
                }
                select{
                    max-width: 130px;
                    border: none;
                    padding: 0px 10px;
                    min-height: auto;
                }
            }

            &.span2{
                grid-column: span 2;
            }
            &.span3{
                grid-column: span 3;
            }
            .chart-box{
                text-align: center;
                svg{
                    max-width: 100%;
                }
                position: relative;
            }
            .donutCount {
                position: absolute;
                top: 41%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #000;
                .totalValue {
                    font-size: 16px;
                    font-weight: 700;
                }
              }
            .dash-alert-content{
                li{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 18px 0;
                    border-bottom: 1px solid #F2F2F2;
                    &:first-child{
                        padding-top: 0px;
                    }
                    .dash-alert-title{
                        font-size: 14px;
                        line-height: 19px;
                        font-weight: 500;
                        color: #595959;
                    }
                    .dash-alert-value{
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 400;
                        color: #333333;
                    }
                    &:last-child{
                        border-bottom: none;
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                }
            }
            .dash-table-wrapper{
                table{
                    margin-bottom: 0;
                    td,th {
                        white-space: nowrap;
                        border: none;
                    }
                    th{
                        background-color: #FAFBFC;
                        font-size: 12px;
                        font-weight: 600;
                        color: #8C8C8C;
                        line-height: 16px;
                        padding: 14px 12px;
                        text-transform: uppercase;
                        min-width: 200px;
                    }
                    td{
                        font-size: 14px;
                        font-weight: 400;
                        color: #8C8C8C;
                        line-height: 19px;
                        padding: 12px 12px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}