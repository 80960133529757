.add-role-box {
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    padding: 16px;
    form{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 24px;
        min-height: 100%;
        label {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #737373;
        }
        .input-with-button {
            display: flex;
            gap: 8px;
            justify-content: space-between;
            button {
              border: 1px solid #d9d9d9;
              background-color: transparent;
              min-width: max-content;
              border-radius: 4px;
              font-size: 14px;
              color: #737373;
            }
            button.use-unit-opt-btn {
              min-width: 40px;
            }
          }
        .full-width {
            grid-column: span 2;
        }
        button{
            min-width: 108px;
        }
        .item-checkbox-content {
            height: 100%;
            display: flex;
            width: 100%;
            align-items: center;
            padding-top: 30px;
        }
        .role-permission-container{
            margin-bottom: 20px;
            .role-permission-header {
                display: flex;
                align-items: center;
                gap: 24px;
                h5{
                    margin: 0;
                    position: relative;
                    &:after {
                        content: "";
                        width: 1px;
                        background: #d9d9d9;
                        height: 25px;
                        position: absolute;
                        right: -12px;
                        top: 0px;
                    }

                }
            }
            .role-permission-option-wrapper{
                margin-top: 12px;
                .accordion{
                    margin-bottom: 12px;
                    .accordion-header{
                        .accordion-button{
                            padding: 12px 16px;
                            &:focus{
                                box-shadow: none;
                            }
                            &:not(.collapsed){
                                background-color: #ffffff;
                            }
                            &:after{
                                background-size: 17px;
                            }
                            label{
                                font-size: 14px;
                                line-height: 19px;
                                font-weight: 600;
                                color: #333333;
                            }
                        }
                    }
                    .accordion-body{
                        padding: 1rem 2.5rem;
                        .role-permission-opt-container{
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            gap: 20px;
                            .role-permission-opt-item{
                                .form-check-label{
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 19px;
                                    color: #333333;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}