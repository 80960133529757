.usage-trans-page-wrapper{
    .usage-trans-profile-header{
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2{
            font-size: 28px;
            font-weight: 600;
            line-height: 38px;
            color: #044D6E;
            position: relative;
            margin: 0;
        }
    }
    .profile-detail-box-wrapper{
        border: 1px solid #F2F2F2;
        border-radius: 8px;
        padding: 16px;
        min-height: 350px;
        .profile-detail-box {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            .profile-info-box {
                height: auto;
                label {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    color: #8C8C8C;
                }
                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 19px;
                    color: #333333;
                    margin: 0;
                    &.disabled-record{
                        background-color: #e9ecef;
                        color: #BFBFBF;
                    }
                }
                &.full-width {
                    grid-column: span 3;
                }
            }
        }
    }
}
.edit-athena {
    form {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 16px;
        min-height: 100%;
        label {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #737373;
        }
        .colspan2 {
          grid-column: span 2;
        }
        .full-width {
          grid-column: span 3;
        }

        .rowspan2 {
          grid-row: span 2;
        }

      }
}