.profile-detail-box-wrapper{
    &.label-details{
        .profile-detail-box{
            grid-template-columns: repeat(2, 1fr);
            .profile-info-box{
                &.full-width {
                    grid-column: span 2;
                }
                p{
                    max-height: 180px;
                    overflow: auto;
                }
            }
        }        
    }
}