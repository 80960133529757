.add-refrigerator-box {
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    padding: 16px;

    form {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 24px;
        min-height: 100%;

        label {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #737373;
        }

        .full-width {
            grid-column: span 3;
        }

        button {
            min-width: 108px;
        }

        .item-checkbox-content {
            height: 100%;
            display: flex;
            width: 100%;
            align-items: center;
            // padding-top: 30px;
        }
    }
}