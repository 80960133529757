@import "../../styles/variables";
.login-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;
    height: 100%;
    .login-content{
        width: 400px;
        .forgot-pass-link-box{
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          .remember-me-check{
            font-size: 15px;
          }
          .form-check-inline{
            margin-right: 8px;
          }
        }
        h4{
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.2;
            letter-spacing: 0.02em;
            margin-bottom: 10px;
        }
        p{
            color: #737373;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 32px;
        }
        label.form-label {
            font-size: 12px;
            font-weight: 500;
            color: #737373;
        }
        .form-control{
            font-size: 14px;
            height: 40px;
            padding: 8px 16px;
            &:focus, &:active{
                color: var(primary);
                border-color: #044D6E;
                box-shadow: none;
            }
        }
        .btn-link{
            color: $primary-800;
            text-align: right;
            text-decoration: none;
            font-size: 16px;
            font-weight: 600;
            padding: 0;
        }
        .login-error {
          margin-top: 0.25rem;
          font-size: 0.825em;
          color: var(--bs-form-invalid-color);
        }
        .password-field-box{
            position: relative;
            .form-control{
                padding-right: 35px;
            }
            i {
                position: absolute;
                right: 10px;
                top: 40px;
                cursor: pointer;
            }
        }
    }
    p {
      color: #737373;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 32px;
    }
    label.form-label {
      font-size: 12px;
      font-weight: 500;
    }
    .form-control {
      font-size: 14px;
      height: 40px;
      padding: 8px 16px;
      &:focus,
      &:active {
        color: var(primary);
        border-color: #044d6e;
        box-shadow: none;
      }
    }
    .btn-link {
      color: $primary-800;
      text-align: right;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      padding: 0;
    }
    .login-error {
      margin-top: 0.25rem;
      font-size: 0.825em;
      color: var(--bs-form-invalid-color);
      position: relative;
    }
}
  .login-content img {
    margin-bottom: 64px;
  }
  .copy-text {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
  }

.login-image-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 55px 95px;
    height: 100%;
    h1{
        margin: 0;
        font-size: 3rem;
        letter-spacing: 0.02em;
        font-weight: 700;
        line-height: 65px;
        color: #333333;
        margin-bottom: 20px;
    }
    h4{
        margin: 0;
        font-size: 32px;
        font-weight: 400;
        line-height: 43px;
        color: #333333;
        letter-spacing: 0.02em;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #333333;
    }
    .url-link-box{
      margin-top: 50px;
    }
}
